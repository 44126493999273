import React from 'react';
import * as styles from './why-bitnimbus.module.scss';
import Layout from '../components/Layout';
import Container from '../components/Container';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Markdown from 'markdown-to-jsx';
import Seo from '../components/Seo';

const contentQuery = graphql`
  query {
    markdownRemark {
      html
      tableOfContents
      rawMarkdownBody
    }
  }
`;

const WhyBitnimbus = () => {
  const data = useStaticQuery(contentQuery);

  const getHeadingId = (children: string) => {
    const tokens = children.split(' ');
    const isSpecialCharacter = (ch: string) =>
      (ch < 'a' || ch > 'z') && (ch < '0' || ch > '9');
    const result = tokens.map((token: string) => {
      let alphaNumericToken = '';
      for (let i = 0; i < token.length; i++) {
        const ch = token.charAt(i);
        if (isSpecialCharacter(ch)) {
          continue;
        }
        alphaNumericToken += ch;
      }
      return alphaNumericToken;
    });
    return result.join('-');
  };

  return (
    <Layout>
      <section>
        <Container className={styles.theme}>
          <div>
            <div className={styles.titleContainer}>
              <div className={styles.section}>
                <h1 className={styles.title}>
                  Reasons to choose Bitnimbus.io Managed Kafka
                </h1>
                <p style={{ lineHeight: '2rem' }}>
                  At Bitnimbus.io, we've crafted a fully managed Kafka solution
                  that prioritizes performance, transparency, and developer
                  experience. Here's what sets us apart:
                </p>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.contentSection}>
                <ReactMarkdown
                  components={{
                    h2({ children }) {
                      return (
                        <h2
                          id={
                            typeof children === 'string'
                              ? getHeadingId(children.toLowerCase())
                              : children?.toString()
                          }
                        >
                          {children}
                        </h2>
                      );
                    },
                  }}
                >
                  {data.markdownRemark.rawMarkdownBody}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export const Head = () => {
  return (
    <Seo
      title="Why us - Bitnimbus.io"
      canonical="https://bitnimbus.io/why-bitnimbus"
    />
  );
};

export default WhyBitnimbus;
